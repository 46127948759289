<template>
    <div class="content-wrapper">
        <div class="content">
            <OnBoarding AuthorizeCRMSystem></OnBoarding>
            <component :is="activeComponent" v-if="activeComponent" class="mt-12"></component>
        </div>
    </div>
</template>

<script>
import YClientsAuth from '@/components/connectors/yClients/Auth.vue'
import YClientsSimpleAuth from '@/components/connectors/yClients/AuthSimple.vue'
import UniverseSoftAuth from '@/components/connectors/universeSoft/Auth.vue'
import SycretAuth from '@/components/connectors/sycret/Auth.vue'
import SonlineAuth from '@/components/connectors/sonline/Auth.vue'
import ArnicaAuth from '@/components/connectors/arnica/Auth.vue'
import AltegioAuth from '@/components/connectors/altegio/Auth.vue'
import ProfSalonBatAuth from '@/components/connectors/profSalonBat/Auth.vue'
import ArchimedAuth from '@/components/connectors/archimed/Auth.vue'
import dentalProAuth from '@/components/connectors/dentalPro/Auth.vue'
import KlientixAuth from '@/components/connectors/klientix/Auth.vue'
import Cloud1CAuth from '@/components/connectors/cloud1C/Auth.vue'
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'
import DikidiAuth from '@/components/connectors/dikidi/Auth.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import { computed, getCurrentInstance, onMounted, ref, watchEffect } from 'vue'
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
export default {
    name: 'AuthorizationInCRMSystem',

    components: {
        OnBoarding,
        YClientsAuth,
        YClientsSimpleAuth,
        UniverseSoftAuth,
        SycretAuth,
        SonlineAuth,
        ArnicaAuth,
        AltegioAuth,
        ProfSalonBatAuth,
        ArchimedAuth,
        dentalProAuth,
        KlientixAuth,
        Cloud1CAuth,
        DikidiAuth,
    },

    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const currentAddingConnector = sessionStorage.getItem('currentAddingConnector')
        const router = useRouter()
        const store = root.$store
        const { project } = useProject()

        const views = [
            { connectorType: CONNECTOR_TYPES.yClients, component: YClientsSimpleAuth },
            { connectorType: CONNECTOR_TYPES.universeSoft, component: UniverseSoftAuth },
            { connectorType: CONNECTOR_TYPES.sycret, component: SycretAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBat, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBatV2, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.sonline, component: SonlineAuth },
            { connectorType: CONNECTOR_TYPES.arnica, component: ArnicaAuth },
            { connectorType: CONNECTOR_TYPES.altegio, component: AltegioAuth },
            { connectorType: CONNECTOR_TYPES.archimed, component: ArchimedAuth },
            { connectorType: CONNECTOR_TYPES.cloud1C, component: Cloud1CAuth },
            { connectorType: CONNECTOR_TYPES.dentalPro, component: dentalProAuth },
            { connectorType: CONNECTOR_TYPES.klientix, component: KlientixAuth },
            { connectorType: CONNECTOR_TYPES.dikidi, component: DikidiAuth },
        ]

        const currentAddingConnectorType = () => {
            return currentAddingConnector.replace(/"/g, '')
        }

        const activeComponent = computed(() => {
            const currentConnectorType = currentAddingConnectorType()
            return views.find((el) => el.connectorType === currentConnectorType)?.component
        })

        const next = () => {
            store.commit('project/updateCurrentAddingConnector', 'whatsappBasis')

            const projectId = router.route.value.params.id
            router.router.push(`/project/${projectId}/onBoarding/BotSettingsView`)
        }

        const login = async () => {
            const loggedAddingConnector = ref(JSON.parse(sessionStorage.getItem('loggedAddingConnector')))
            const res = await root.$store.dispatch('project/addConnector', loggedAddingConnector.value)
            if (res.error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                return
            }

            const connectorId = loggedAddingConnector.value.connectorId
            await root.$store.dispatch('project/updateConnectorSettings', {
                connectorId,
                settings: {
                    isDraft: false,
                },
            })
            next()
        }

        root.$on('login', login)

        return {
            activeComponent,
        }
    },
}
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
</style>